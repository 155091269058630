import React from "react";
import ReactPageScroller from "react-page-scroller";
import "../styles/App.css"

const App = () => {
  return (
    <ReactPageScroller>
      <div className="video-container">
        <video autoPlay loop muted playsInline>
          <source src="https://emilmadsen.art/assets/videos/Smoke.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="info-section">
        <div className="inner-card">
          <h3 className="centered-content">
            Portfolio coming soon. Keep an eye out.
          </h3>

          <a className="instagram-link" href="https://www.instagram.com/emil.mdsn/?igsh=eDY5MmJocGkzdmFt" target="_blank" rel="noopener noreferrer">
            <img
              src="https://emilmadsen.art/assets/images/instagram_icon.png"
              alt="Icon"
              className="icon"
            />
            <p>
              @emil.mdsn
            </p>
          </a>

        </div>
      </div>    
    </ReactPageScroller>
  );
};

export default App;
